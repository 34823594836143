.ant-back-top
{
    right: 50px !important;
}

.ant-modal-content {
    border-radius: 10px !important;
}

.ant-modal-header {
    border-radius: 10px !important;
}

.ant-message {
    padding-top: 100px !important;
}

.ant-drawer {
    z-index: 9999;
}

/* ant modal */
.ant-modal-mask {
    z-index: 100;
}
.ant-modal-wrap {
    z-index: 101;
}

.ant-btn-primary {
    border-radius: 10px !important;
    font-weight: 600 !important;
    height: 40px !important;
    background-color: #54b4a4 !important;
    min-width: 100px;
}

.ant-btn-default {
    color: #fff;
    border-radius: 10px !important;
    font-weight: 600 !important;
    height: 40px !important;
    background-color: #19181c !important;
    min-width: 100px;
}

